import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Plans, Plan } from '../../components/Plan'
import FAQs from '../../components/FAQs'

import './pricing.css'

const Pricing = () => (
    <Layout>
            <Hero 
                large
                title='Service that scales with you'
                subTitle='Try Tueri free for 14 days, no credit card required'
            />

            <Plans>

                <Plan
                    title='15GB'
                    // subTitle='Starting at'
                    price='50'
                    priceYearly='40'
                    // features={[
                    //     '10 GB Storage',
                    //     '40 GB Bandwidth',
                    //     'Unlimited Transformations',
                    //     'Integrated CDN',
                    //     'Storage Aliases',
                    //     'Unlimited Users',
                    //     'Basic Email Support',
                    // ]}

                    features={[
                        '15 GB storage',
                        '150GB outbound bandwidth',
                        'Unlimited inbound bandwidth',
                        'Remote storage sources',
                        'Integrated CDN',
                        'Unlimited users',
                        'Email support'
                    ]}
                />

                <Plan
                    title='50GB'
                    // subTitle='Starting at'
                    price='150'
                    priceYearly='120'
                    // features={[
                    //     '40 GB Storage',
                    //     '160 GB Bandwidth',
                    //     'Unlimited Transformations',
                    //     'Integrated CDN',
                    //     'Storage Aliases',
                    //     'Unlimited Users',
                    //     'Priority Email Support'
                    // ]}  

                    features={[
                        '50GB storage',
                        '500GB outbound bandwidth',
                        'Unlimited inbound bandwidth',
                        'Remote storage sources',
                        'Integrated CDN',
                        'Unlimited users',
                        'Email and phone support'
                    ]}
                />

                <Plan
                    title='200GB'
                    // subTitle='Starting at'
                    price='500'
                    priceYearly='400'
                    // features={[
                    //     '200 GB Storage',
                    //     '800 GB Bandwidth',
                    //     'Unlimited Transformations',
                    //     'Integrated CDN',
                    //     'Storage Aliases',
                    //     'Unlimited Users',
                    //     'Bring Your Own S3 Storage',
                    //     'Priority Email Support',
                    //     'Basic Phone Support'
                    // ]}

                    features={[
                        '200GB storage',
                        '2TB outbound bandwidth',
                        'Unlimited inbound bandwidth',
                        'Remote storage sources',
                        'Integrated CDN',
                        'Unlimited users',
                        'Priority support'
                    ]}
                />

                <Plan
                    title='1TB+'
                    subTitle='Starting at'
                    price='2,000'
                    priceYearly='1,600'
                    buttonText='Request a callback'
                    features={[
                        'Customized solutions',
                        '1TB+ storage',
                        '10TB+ outbound bandwidth',
                        'Unlimited inbound bandwidth',
                        'Remote storage sources',
                        'Integrated CDN',
                        'Unlimited users',
                        'Priority support',
                        'Dedicated account manager'
                    ]}
                />
            </Plans>

            <h2>FAQs</h2>

            <FAQs
                faqs={[
                    {
                        icon: 'cloud_download',
                        title: 'Bandwidth and Storage',
                        body: `All plans include unlimited inbound bandwidth. Outbound bandwidth is calculated based on the optimized image that is delivered, typically about 0.5% of the original image size. We've set our bandwidth limits so high that 99% of Tueri users won't come close to reaching any limits. If you're a "one percenter", extra bandwidth is billed at $0.10/GB`
                    },
                    {
                        icon: 'lock',
                        title: 'Security is Free',
                        body: `Data transfer is automatically secured with end-to-end SSL Encryption (HTTPS). Your files are encrypted on physical disks with 256-bit AES-XTS full-disk encryption. Files are distributed using a fault-tolerant placement technique called erasure coding. Your files can tolerate multiple host failures without blocking any client I/O or experiencing any data loss.`
                    },
                    {
                        icon: 'storage',
                        title: 'Remote Storage Sources',
                        body: `A remote storage source allows you to use Tueri to fetch images hosted remotely and seamlessly optimize and deliver them on the fly. The first time a remote image is fetched, it is cached in our storage network and subsequent requests are delivered directly from the cache for increased delivery speeds. Cached remote images are calculated as part of your storage, but will automatically expire if they are not receiving any activity.`
                    },
                    {
                        icon: 'receipt',
                        title: 'No Contracts',
                        body: 'Tueri is a recurring subscription service. You may upgrade, downgrade or cancel at any time. No questions asked.'
                    },
                    {
                        icon: 'verified_user',
                        title: '30 Day Guarantee',
                        body: `If your not totally satisifed with Tueri and cancel your account within 30 days of your payment, we'll issue a full refund.`
                    },
                    {
                        icon: 'autorenew',
                        title: 'Auto-Renewal Terms',
                        body: `Tueri is a subscription service and will continue until you cancel. It auto-renews at the end of each term and you will be charged for the subsequent term.`
                    },
                    
                    // {
                    //     icon: 'favorite',
                    //     title: 'Nonprofit Discount',
                    //     body: 'We want to give back! Nonprofit users qualify for a 50% lifetime discount off any paid plain.'
                    // },
                    // {
                    //     icon: 'school',
                    //     title: 'Education Discount',
                    //     body: 'At Tueri we believe in continuous learning. Education users qualify for a 50% lifetime discount off any paid plain.'
                    // },
                ]}
            />

    </Layout>
)

export default Pricing

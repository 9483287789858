import React from 'react'
import './faqs.css'

export default function FAQs({faqs}) {

    return(
        <div className='faq__container'>
            {
                faqs.map(({ icon, title, body }, i) => {
                    return <div className='faq'><h3><i className='material-icons'>{ icon }</i>{ title }</h3>{ body }</div>
                })
            }
        </div>
    )

}
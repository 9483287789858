import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import './plan.css'

function Plan({ recurs, title, subTitle, price, priceYearly, features, buttonText }) {

    return(
        <div className='plan__container'>
            <div className='plan__title'>{ title }<div className='plan__subTitle'>{ subTitle }</div></div>
            <div className='plan__price'>{price === 'FREE' ? null : '$'}{ recurs === 'monthly' ? price : priceYearly }</div>
            <div className='plan__per'>/month</div>
            <Button link large fullWidth>{ buttonText ? buttonText : 'Sign up for free' }</Button>
            <div className='plan__features'>
                {
                    features.map((feature, i) => (
                        <div className='plan__feature'>
                            <i className='material-icons'>check</i>
                            { feature }
                        </div>
                    ))
                }
            </div>
        </div>
    )

}

Plan.propTypes = {
    recurs: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    price: PropTypes.string.isRequired,
    priceYearly: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired
}

class Plans extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            recurs: 'yearly'
        }
    }

    render() {

        const { children } = this.props
        const { recurs } = this.state

        const childrenWithProps = React.Children.map(children, child => {
            return React.cloneElement(child, { recurs })
        })

        return <div className='pricing__wrapper'>
                <div className='interval__container'>
                    <button className={ this.state.recurs === 'monthly' ? 'active' : '' } onClick={() => { this.setState({ recurs: 'monthly' }) }}>Monthly</button>
                    <button className={ this.state.recurs === 'yearly' ? 'active' : '' } onClick={() => { this.setState({ recurs: 'yearly' }) }}>
                        Annually
                        <div className='annual__discount'>Save 20%</div>
                    </button>
                    
                </div>
                <div className='pricing__container'>{childrenWithProps}</div>
            </div>

    }

}

export {
    Plans,
    Plan
}